<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <md-button
          class="md-primary float-right md-round md-sm"
          style="margin-top: -20px"
          :disabled="!tableHasContent"
          @click="onDownload"
        >
          <md-icon>get_app</md-icon>
          Download as XLS
        </md-button>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-20">
            <AccommodationTypeSelect
              v-model="filters.accommodation_type_id"
            />
          </div>
          <div class="md-layout-item md-size-20">
            <FormSelect
              v-model="filters.airport_transfer"
              label="Airport Transfer"
              :options="boolOpts"
              icon="local_shipping"
            />
          </div>
          <div class="md-layout-item md-size-20">
            <FormSelect
              v-model="filters.booked"
              label="Booked"
              :options="boolOpts"
              icon="bookmark"
            />
          </div>
          <div class="md-layout-item md-size-20">
            <FormDate
              v-model="filters.check_in_from"
              label="Check In From"
            />
          </div>
          <div class="md-layout-item md-size-20">
            <FormDate
              v-model="filters.check_in_to"
              label="Check In To"
            />
          </div>
          <div class="md-layout-item md-size-100 mb-5">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"

              @changed="getStudents"
              @onShowDetails="onShowDetails"
              @onCheck="onCheck"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import downloadFile from '@/utils/downloadFile';
import {
  AccommodationTypeSelect,
} from '@/components/Inputs/selects';
import {
  FormSelect,
  FormDate,
} from '@/components/Inputs';

export default {
  components: {
    VueTable,
    AccommodationTypeSelect,
    FormSelect,
    FormDate,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'student_number',
            mask: 'Student Number',
          },
          {
            title: 'full_name',
            mask: 'full name',
          },
          {
            title: 'email',
            mask: 'Email',
          },
          {
            title: 'accommodation_type',
            mask: 'accommodation type',
          },
          {
            title: 'check_in',
            mask: 'check in date',
            sortable: true,
          },
          {
            title: 'weeks',
            mask: 'Weeks',
          },
          {
            title: 'airport_transfer',
            mask: 'airport transfer',
          },
          {
            title: 'booked',
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Set Booked',
            callback: 'onCheck',
            icon: 'check',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
        ],
        values: {},
      },
      loading: false,
      filters: {
        accommodation_type_id: null,
        airport_transfer: null,
        booked: null,
        check_in_from: null,
        check_in_to: null,
      },
      boolOpts: [
        { id: 'null', name: 'ALL' },
        { id: true, name: 'YES' },
        { id: false, name: 'NO' },
      ],
    };
  },
  computed: {
    tableHasContent() {
      return this.vTable.values?.data?.length > 0;
    },
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    onFilter() {
      this.getStudents({ page: 1 });
    },
    getStudents(params) {
      this.filters = {
        ...this.filters,
        ...params,
      };
      this.loading = true;
      this.request('student_accommodations/report', 'get', { ...this.filters }, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data.map((x) => {
          x.onCheck = x.booked === 'NO';
          return x;
        });
      }, () => {
        this.loading = false;
      });
    },
    onDownload() {
      this.loading = true;
      this.request('student_accommodations/report', 'download', { download: true, ...this.filters }, ({ data }) => {
        const filename = 'Students_Accommodations_report.xlsx';
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
    onShowDetails(item, isMiddleClick) {
      this.openLink({ name: 'StudentResume', params: { id: item.student_id } }, isMiddleClick);
    },
    onCheck(item) {
      this.fireConfirm('Updating Accommodation details', 'This will set the Accommodation as Booked, are you sure?')
        .then(() => {
          this.requestAsync(`student_accommodations/${item.student_accommodation_id}/check`, 'put')
            .then((data) => {
              this.fireSuccess(data);
              this.$refs.vtable.init();
            });
        });
    },
  },
};
</script>
